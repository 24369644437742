import React from 'react'
import styles from './Tracker.module.scss'

const Tracker = ({children, handleMouseMove}) => {
  return (
    <div 
      className={styles.tracker}
      onMouseOver={handleMouseMove}
      onMouseMove={handleMouseMove}
    >
      {children}
    </div>
  )
}

export default Tracker