import React, { useState } from 'react'
import { Link } from 'gatsby'
import styles from './Menu.module.scss'
import Tracker from '../Tracker'
import { isMobile } from "react-device-detect";

const Menu = ({data, projectPage }) => {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [movement, setMovement] = useState(0);
  const [image, setImage] = useState(0);
  const [visibility, setVisibility] = useState(false);

  const handleMouseMove = (e) => {
    setMouse({x: e.clientX, y: e.clientY});
    setVisibility(true);
    handleMovement(e);
  }

  const handleMovement = (e) => {
    const count = data.length - 1;

    if (movement >= 25) {
      if (image === count) {
        setImage(0);
      } else {
        setImage(image + 1);
      }
      setMovement(0);
    } else if (movement <= -25) {
        if (image === 0) {
          setImage(count);
        } else {
          setImage(image - 1);
        }
      setMovement(0);
    } else {
      setMovement(movement + e.movementX);
    }
  }

  const imgPosition = {
    left: mouse.x,
    top: mouse.y,
  }

  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <menu className={projectPage === true ? styles.projectMenu : styles.menu}>
      <ul className={styles.menuList}>
        {data.map((item, index) => (
          <li key={index}>
            <Tracker
              mouse={mouse}
              setMouse={setMouse}
              handleMouseMove={handleMouseMove}
            >
              <Link 
                className={`/${item.slug}` === pathname ? 'menuListItem active' : 'menuListItem'}
                activeClassName="active"
                to={`/${item.slug}` === pathname ? '/' : `/${item.slug}`}
              >
                <span>{item.title}</span>
                <span>{item.year || '—'}</span> 
              </Link>
            </Tracker>
            <div
              style={imgPosition}
              className={styles.tracker}
            >
              {(item.assets !== null) && (!isMobile)
                ? <ul className={visibility === true ? styles.trackerList : styles.hidden}>
                    {item.assets
                      .filter(item => item !== undefined)
                      .map((item, index) => (
                      <li key={index} style={image === index ? null : { display: 'none' }}>
                        {item.file.contentType.includes('image')
                          ? <picture>
                              <source srcSet={item.fixed.srcSet} />
                              <source srcSet={item.fixed.srcSetWebp} />
                              <img
                                alt='Isaac Daniels'
                                loading='lazy'
                                src={item.fixed.src}
                              />
                            </picture> 
                            : null
                        }
                        {item.file.contentType.includes('video')
                          ? <video 
                              autoPlay 
                              muted 
                              loop 
                              width='150'
                            >
                              <source src={item.file.url} type={item.file.contentType} />
                            </video>
                          : null
                        }
                      </li>
                    ))}
                  </ul>
                : null
              }
            </div>
          </li>
        ))}
      </ul>
      <Link 
        to='/'
        className={styles.menuClose}
      >
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1 9M9 9L1 1" stroke="black" />
        </svg>
        <span>Close</span>
      </Link>
    </menu>
  )
}

export default Menu
